const firebaseConfig = {
  projectId: "the-braemar",
  appId: "1:432596109618:web:f5e00679fadfabde442f2b",
  databaseURL:
    "https://the-braemar-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-braemar.appspot.com",
  locationId: "us-central",
  apiKey: "AIzaSyC_elBF8HnYf1wZMUIPCv2YMDNpeK05vp0",
  authDomain: "the-braemar.firebaseapp.com",
  messagingSenderId: "432596109618",
  measurementId: "G-E5QBWBR8F0",
};

export default firebaseConfig;
